<template>
	<div>
		<SettingsDocumentsUpload />

		<SettingsDocumentsTemplates />

		<SettingsDocumentsAll />
	</div>
</template>

<script>
export default {
	metaInfo() {
		return {
			title: `${this.$t('nav.settings')} - ${this.$t('settings.documents')}`,
		}
	},
	components: {
		SettingsDocumentsUpload: () => import('@/components/settings/SettingsDocumentsUpload.vue'),
		SettingsDocumentsAll: () => import('@/components/settings/SettingsDocumentsAll.vue'),
		SettingsDocumentsTemplates: () => import('@/components/settings/SettingsDocumentsTemplates.vue'),
	},
}
</script>
